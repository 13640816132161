import React, { useEffect, useState } from "react"

import "./typing-text.scss"

const TypingRow = ({ id, text, itemIndex, step, setNextStep }) => {
  const [isTyped, setIsTyped] = useState(false)

  useEffect(() => {
    let cleanupFunction = false
    const typewriter = document.getElementById(id)
    let index = 0

    function type() {
      if (index < text.length) {
        typewriter.classList.add("visible")

        typewriter.innerHTML =
          text.slice(0, index) + '<span class="blinking-cursor"></span>'
        index++
        setTimeout(type, 7)
      } else {
        typewriter.innerHTML = text.slice(0, index)
        if (!cleanupFunction && index > 0 && index === text.length) {
          setIsTyped(true)
          setNextStep(+itemIndex + 1)
        }
      }
    }
    // start typing
    if (!isTyped && itemIndex === step) setTimeout(type, 700)
    return () => (cleanupFunction = true)
  }, [itemIndex, step, isTyped, text, id]) // eslint-disable-line

  return <p id={id} className="typewriter" />
}

export default TypingRow
