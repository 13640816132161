// extracted by mini-css-extract-plugin
export var aiBlock = "ai-ml-software-development-module--aiBlock--0a2f7";
export var benefits = "ai-ml-software-development-module--benefits--47bec";
export var billetShadow = "ai-ml-software-development-module--billet-shadow--c8473";
export var cardWrapper = "ai-ml-software-development-module--cardWrapper--34da9";
export var carouselWrapper = "ai-ml-software-development-module--carouselWrapper--d9306";
export var colorScheme__background__grayPale = "ai-ml-software-development-module--colorScheme__background__gray-pale--92f0b";
export var dialogImg = "ai-ml-software-development-module--dialogImg--139ae";
export var dialogText = "ai-ml-software-development-module--dialogText--cc4ed";
export var dialogWrapper = "ai-ml-software-development-module--dialogWrapper--0be76";
export var headline = "ai-ml-software-development-module--headline--935dd";
export var headline_grid = "ai-ml-software-development-module--headline_grid--3286e";
export var icomoon = "ai-ml-software-development-module--icomoon--4c4aa";
export var imgContainer = "ai-ml-software-development-module--imgContainer--185d4";
export var industries = "ai-ml-software-development-module--industries--c5021";
export var lowercase = "ai-ml-software-development-module--lowercase--c4265";
export var transition = "ai-ml-software-development-module--transition--24b8f";
export var transitionBackground = "ai-ml-software-development-module--transition-background--18e61";
export var transitionReverse = "ai-ml-software-development-module--transition-reverse--3ef5b";
export var uppercase = "ai-ml-software-development-module--uppercase--c8c92";