import React from "react"

export const TITLES = {
  industries: {
    title: (
      <>
        <strong> AI and ML can benefit businesses</strong> across various
        industries in:
      </>
    ),
    strongText: "We advice",
    uppercase: "Our AI software developers work worldwide",
    withIndent: true,
  },
  benefits: {
    title: (
      <>
        <strong>AI and ML software development services</strong> for business
        benefits
      </>
    ),
    strongText:
      "Our team is dedicated to empowering your business with machine learning solutions and AI-based software",
    uppercase: "We help you gain valuable insights",
    withIndent: true,
  },
  services: {
    title: (
      <>
        Our <strong>Artificial Intelligence and Machine Learning</strong>{" "}
        services
      </>
    ),
    strongText: "LaSoft as AI software developer",
    uppercase: "We provide",
    withIndent: true,
  },
}

export const OUR_SERVICES = {
  items: [
    {
      id: 1,
      title: "AI Software For Business",
      text:
        "It helps create cutting-edge AI solutions to streamline company operations, integrate AI into your current tech stack, and accelerate business growth.",
    },
    {
      id: 2,
      title: "Machine Learning Development",
      text: (
        <>
          We help implement ML algorithms that process large data sets, enabling
          our clients to make smart decisions in business processes.
          <br /> The LaSoft team, using AI & ML solutions, enhances Data
          Cleaning, and Big Data Analytics.
        </>
      ),
    },
    {
      id: 3,
      title: "Data Support With AI ML Services",
      text:
        "Develop machine-learning solutions that understand digital images and videos. Process, classify, and update those in the system's database without human interaction.",
    },
    {
      id: 4,
      title: "User Behavior Analytics",
      text:
        "Integrate data-driven analytics into your digital product to understand user behavior and customer preferences. Create digital solutions that increase recommendation accuracy and maximize user engagement.",
    },
  ],
}

export const INDUSTRIES = [
  { name: "Data Analysis and Insights", icon: "icon-connection" },
  { name: "Improved Decision Making", icon: "bg-verify" },
  { name: "Automation of Routine Tasks", icon: "icon-clipboard" },
  { name: "Cost Reduction", icon: "bg-recieve" },
  { name: "Product and Service Innovation", icon: "icon-bulb" },
  { name: "Risk Management", icon: "bg-exclamation" },
  { name: "Supply Chain Optimization", icon: "bg-refresh" },
  { name: "Enhanced Marketing Strategies", icon: "bg-maximize" },
  { name: "Employee Productivity and Engagement", icon: "bg-bullish" },
]

export const SERVICES = [
  {
    id: 1,
    title: "Enhanced User Experience",
    text:
      "AI and ML algorithms can analyze user behavior, preferences, and interactions to provide personalized and intuitive user experiences. It leads to increased user satisfaction, engagement, and retention.",
    icon: "icon-user-verified",
  },
  {
    id: 2,
    title: "Improved Analytics with ML",
    text:
      "Businesses can get accurate data to forecast user trends, preferences, and potential issues. ",
    icon: "bg-bullish",
  },
  {
    id: 3,
    title: "Automation of Repetitive Tasks",
    text:
      "AI-powered automation streamlines routine and time-consuming tasks in the development process. It allows developers to focus on more creative and complex app and web development aspects.",
    icon: "bg-settings",
  },
  {
    id: 4,
    title: "Efficient Bug Detection and Debugging",
    text:
      "ML algorithms can analyze code patterns and identify potential bugs or errors. This approach accelerates the debugging process, minimizing the chances of issues affecting end users.",
    icon: "bg-verified",
  },
  {
    id: 5,
    title: "Security Enhancements",
    text:
      "AI and ML can analyze user behavior, identify anomalies, and protect against cybersecurity risks, ensuring a secure digital environment.",
    icon: "icon-protection",
  },
  {
    id: 6,
    title: "Chatbots and Virtual Assistants",
    text:
      "AI-driven chatbots and virtual assistants enhance customer support and engagement. They provide instant responses to user queries, and guide users to action.",
    icon: "icon-message",
  },
]

export const TYPING_TEXT = [
  { id: "typewriter", text: "Hello, I'm your virtual assistant." },
  {
    id: "typewriter_2",
    text:
      "I'm here to answer your questions, give you information, help you with your tasks and hold conversations on a variety of topics.",
  },
  { id: "typewriter_3", text: "Feel free to ask me anything!" },
]

export const IMAGES = [{ id: "ai-ml-1" }, { id: "ai-ml-2" }, { id: "ai-ml-3" }]
