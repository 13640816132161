import React from "react"

import "../../assets/styles/commons/_icon-badge.scss"

const IndustryCard = ({ item }) => (
  <li className={`icon-badge ${item.icon}`}>
    <p>{item.name}</p>
  </li>
)

export default IndustryCard
