import React, { useState } from "react"
import { InView } from "react-intersection-observer"

import { TYPING_TEXT } from "../../data/ai-ml-software-development"
import "./typing-text.scss"
import TypingRow from "./TypingRow"

const TypingText = () => {
  const [isActiveAnimation, setIsActiveAnimation] = useState(false)
  const [step, setStep] = useState(0)

  function onSetActiveAnimation(inView) {
    if (inView && !isActiveAnimation) {
      setIsActiveAnimation(true)
    }
  }

  return (
    <>
      {isActiveAnimation &&
        TYPING_TEXT.map((item, index) => (
          <TypingRow
            key={item.id}
            id={item.id}
            text={item.text}
            itemIndex={index}
            step={step}
            setNextStep={setStep}
          />
        ))}
      <InView onChange={inView => onSetActiveAnimation(inView)}>
        {({ ref }) => <div className={"hidden-card--js"} ref={ref} />}
      </InView>
    </>
  )
}

export default TypingText
